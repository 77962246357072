<template>
  <v-app>
    <v-card style="width: calc(100vw - 333px);">
      <v-toolbar
        flat
        :color="'#cfdce4'"
        dark
        style="background-color: rgb(14, 74, 196)"
      >
        <v-toolbar-title >
          <span style="">{{names.names}}</span><br>
        </v-toolbar-title>
        <h6 style="float: right;position: absolute;right: 15px;color: #ffffff;font-size: 14px !important;" v-if="names.status === '2'"><i class="fa fa-check" aria-hidden="true"  style="color: #32a856;"></i>Approved on:{{approvedAt}}</h6>
      </v-toolbar>
      <v-tabs vertical>
        <v-tab >
          Student Info
        </v-tab>
        <v-tab >
          Parents Info
        </v-tab>
        <v-tab>
          Study Related
        </v-tab>
        <v-tab>
          Documents  & Class
        </v-tab>
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <div style="width: 1104px; background-color: #f2f2f2;" >
              </div>
              <div style="width: 602px">
                <v-row>
                  <v-col cols="6">
                    <div class="info">
                      <span style="font-family: Sans, Avenir, Helvetica, Arial, sans-serif; font-weight: bold; margin-left: 5px;">Date of birth</span>
                      <br>
                      <span style="margin-left: 10px;">{{studentInfo.dob}}</span>
                    </div>
                  </v-col>
                  <v-col cols="6">
                    <div class="info">
                      <span style="font-family: Sans, Avenir, Helvetica, Arial, sans-serif; font-weight: bold; margin-left: 5px;">Birth place</span>
                      <br>
                      <span style="margin-left: 10px;">{{studentInfo.birth_place}}</span>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <div class="info">
                      <span style="font-family: Sans, Avenir, Helvetica, Arial, sans-serif; font-weight: bold; margin-left: 5px;">Gender</span>
                      <br>
                      <span style="margin-left: 10px;">{{studentInfo.sex ==0 ? 'Female':'Male'}}</span>
                    </div>
                  </v-col>
                  <v-col cols="6">
                    <div class="info">
                      <span style="font-family: Sans, Avenir, Helvetica, Arial, sans-serif; font-weight: bold; margin-left: 5px;">Civil status</span>
                      <br>
                      <span style="margin-left: 10px;">{{studentInfo.civil_status ==1 ? 'Single':studentInfo.civil_status ==2 ?'Married':'Divorced'}}</span>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <div class="info">
                      <span style="font-family: Sans, Avenir, Helvetica, Arial, sans-serif; font-weight: bold; margin-left: 5px;">Email</span>
                      <br>
                      <span style="margin-left: 10px;">{{studentInfo.email}}</span>
                    </div>
                  </v-col>
                  <v-col cols="6">
                    <div class="info">
                      <span style="font-family: Sans, Avenir, Helvetica, Arial, sans-serif; font-weight: bold; margin-left: 5px;">Country</span>
                      <br>
                      <span style="margin-left: 10px;">{{studentInfo.nationality}}</span>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <div style="width: 1104px; background-color: #f2f2f2;" >
              </div>
              <div style="width: 602px">
                <v-row>
                  <v-col cols="6">
                    <div class="info">
                      <span style="font-family: Sans, Avenir, Helvetica, Arial, sans-serif; font-weight: bold; margin-left: 5px;">Father names</span>
                      <br>
                      <span style="margin-left: 10px;">{{parentsInfo.father_names}}</span>
                    </div>
                  </v-col>
                  <v-col cols="6">
                    <div class="info">
                      <span style="font-family: Sans, Avenir, Helvetica, Arial, sans-serif; font-weight: bold; margin-left: 5px;">Mother names</span>
                      <br>
                      <span style="margin-left: 10px;">{{parentsInfo.mother_names}}</span>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <div class="info">
                      <span style="font-family: Sans, Avenir, Helvetica, Arial, sans-serif; font-weight: bold; margin-left: 5px;">Province</span>
                      <br>
                      <span style="margin-left: 10px;">{{parentsInfo.province}}</span>
                    </div>
                  </v-col>
                  <v-col cols="6">
                    <div class="info">
                      <span style="font-family: Sans, Avenir, Helvetica, Arial, sans-serif; font-weight: bold; margin-left: 5px;">District</span>
                      <br>
                      <span style="margin-left: 10px;">{{parentsInfo.district}}</span>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <div class="info">
                      <span style="font-family: Sans, Avenir, Helvetica, Arial, sans-serif; font-weight: bold; margin-left: 5px;">Parent Id Number</span>
                      <br>
                      <span style="margin-left: 10px;">{{parentsInfo.idNumber}}</span>
                    </div>
                  </v-col>
                  <v-col cols="6">
                    <div class="info">
                      <span style="font-family: Sans, Avenir, Helvetica, Arial, sans-serif; font-weight: bold; margin-left: 5px;">Parent phone number</span>
                      <br>
                      <span style="margin-left: 10px;">{{parentsInfo.parent_phone}}</span>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <div style="width: 1104px; background-color: #f2f2f2;" >
              </div>
              <div style="width: 602px">
                <v-row>
                  <v-col cols="6">
                    <div class="info">
                      <span style="font-family: Sans, Avenir, Helvetica, Arial, sans-serif; font-weight: bold; margin-left: 5px;">Diploma number</span>
                      <br>
                      <span style="margin-left: 10px;">{{studyInfo.diploma_number}}</span>
                    </div>
                  </v-col>
                  <v-col cols="6">
                    <div class="info">
                      <span style="font-family: Sans, Avenir, Helvetica, Arial, sans-serif; font-weight: bold; margin-left: 5px;">Place of issue</span>
                      <br>
                      <span style="margin-left: 10px;">{{studyInfo.issued}}</span>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <div class="info">
                      <span style="font-family: Sans, Avenir, Helvetica, Arial, sans-serif; font-weight: bold; margin-left: 5px;">Issue date</span>
                      <br>
                      <span style="margin-left: 10px;">{{studyInfo.issue_date}}</span>
                    </div>
                  </v-col>
                  <v-col cols="6">
                    <div class="info">
                      <span style="font-family: Sans, Avenir, Helvetica, Arial, sans-serif; font-weight: bold; margin-left: 5px;">Section attended</span>
                      <br>
                      <span style="margin-left: 10px;">{{studyInfo.section}}</span>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <div class="info">
                      <span style="font-family: Sans, Avenir, Helvetica, Arial, sans-serif; font-weight: bold; margin-left: 5px;">Option</span>
                      <br>
                      <span style="margin-left: 10px;">{{studyInfo.options}}</span>
                    </div>
                  </v-col>
                  <v-col cols="6">
                    <div class="info">
                      <span style="font-family: Sans, Avenir, Helvetica, Arial, sans-serif; font-weight: bold; margin-left: 5px;">Code</span>
                      <br>
                      <span style="margin-left: 10px;">{{studyInfo.code}}</span>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <div style="width: 1104px; background-color: #f2f2f2;" >
              </div>
              <div style="white-space: nowrap; width: 600px;">
                <v-row>
                  <v-col cols="3" v-for="item in documents" :key="item.id">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <a target="_blank" :href="baseUrl +
                          'assets/uploads/pdf/' + item.document">
                          <v-chip small style="cursor: pointer; color: white; background-color: red;"  v-bind="attrs"
                            v-on="on">
                            {{item.document}}</v-chip>
                        </a>
                      </template>
                      <span>{{item.title}}</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
                <v-row v-if="names.status === '1'">
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <v-select
                      style="margin-bottom:-27px !important;"
                      label="Select student choice"
                      outlined
                      dense
                      :items="faculty"
                      v-validate="'required'"
                      name="Faculty"
                      v-model="selected"
                      @change="fetchSelectedClass"
                      @click="appear = false"
                    >
                    </v-select>
                    <span class="text-danger text-sm" v-show="errors.has('Faculty')">{{ errors.first('Faculty') }}</span>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <v-select
                      style="margin-bottom:-27px !important;"
                      label="Choose Class"
                      outlined
                      dense
                      :items="classes"
                      v-validate="'required'"
                      name="Class"
                      v-model="classe"
                      @change="fetchSClassInfo"
                    >
                    </v-select>
                    <span class="text-danger text-sm" v-show="errors.has('Faculty')">{{ errors.first('Faculty') }}</span>
                  </v-col>
                </v-row>
                <span v-if="names.status === '2'" style="font-size: 17px">List of faculty and deprtment respectvely <strong>{{names.names}}</strong> has chosen during registration</span>
                <v-row v-if="names.status === '2'">
                  <v-col cols="12" >
                    <div style="display: inline-block;margin-right: 10px" v-for="choice in studentChoices" :key="choice.id" class="info" >
                      <span style="font-family: Sans, Avenir, Helvetica, Arial, sans-serif; font-weight: bold; margin-left: 5px;">{{choice.faculty}}</span>
                      <br>
                      <span style="margin-left: 10px;">{{choice.department}}</span>
                      <span v-if="choice.faculty === studentClass.faculty && choice.department === studentClass.department"><i class="fa fa-check" aria-hidden="true"  style="color: #32a856;"></i></span>
                    </div>
                  </v-col>
                </v-row>
                <v-row v-if="names.status === '2'">
                  <v-col cols="3">
                    <div class="info" style="width: 300px !important;">
                      <span style="font-family: Sans, Avenir, Helvetica, Arial, sans-serif; font-weight: bold; margin-left: 5px;">Assigned Class</span>
                      <br>
                      <span style="margin-left: 10px;">{{studentClass.title}}</span>
                    </div>
                  </v-col>
                </v-row>
                <div v-if="names.status === '1'">
                  <v-checkbox
                    v-model="checkbox"
                    label="Confirm"
                    @click="check"
                    :disabled="disableCheckbox"
                  ></v-checkbox>
                  <span>
                    <v-btn small color="blue" style="text-transform: none; color: white" :disabled="isDisabled" @click="approve">Approve</v-btn>&nbsp;
                    <v-btn small color="blue" style="text-transform: none; color: white" :disabled="isDisabled" @click="rejectModal = true">Reject</v-btn>
                  </span>
                  <CircleBars v-if="loading"/>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-card>
    <v-dialog
      v-model="rejectModal"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Rejecting {{names.names}}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-textarea
                  label="specify the Reason"
                  v-model="msg"
                  name="Status"
                  v-validate="'required'"
                  outlined
                ></v-textarea>
                <span class="text-danger text-sm" v-show="errors.has('Status')">{{ errors.first('Status') }}</span>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="rejectModal = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="reject"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      :timeout="2000"
      :value="true"
      absolute
      top
      color="success"
      right
      text
      v-if="isSuccess"
    >
      {{successmsg}}
    </v-snackbar>
  </v-app>
</template>

<script>
import CircleBars from '../statistics/progress-bars/Widgets/CircleBars'
export default {
  name: 'SingleStudent',
  components: {
    CircleBars,
  },
  data () {
    return {
      parentsInfo: [],
      studyInfo: [],
      documents: [],
      image: [],
      faculty: [],
      classes: [],
      selected: null,
      classe: null,
      checkbox: false,
      isDisabled: true,
      capacity: [],
      available: '',
      studentsInClass: [],
      appear: false,
      names: [],
      status: '',
      approved: false,
      studentChoices: [],
      studentClass: [],
      rejectModal: false,
      msg: '',
      isSuccess: false,
      successmsg: '',
      disableCheckbox: false,
      approvedAt: '',
      loading: false,
      studentInfo: [],
      requestedLevel: ''
    }
  },
  computed: {
    baseUrl () {
      return this.axios.defaults.baseURL
    },
  },
  mounted () {
    this.fetchParentsInfo()
    this.fetchStudyInfo()
    this.fetchStudentsImages()
    this.fetchSelectedFaculty()
    this.fetchStudentName()
    this.fetchStudentChoices()
    this.getStudentInformation()
  },
  methods: {
    async getStudentInformation () {
      const url = window.location.pathname
      const id = url.substring(url.lastIndexOf('/') + 1)
      if (localStorage.getItem('logged_user')) {
        this.logger = JSON.parse(localStorage.getItem('logged_user'))
      }
      this.header = this.logger.accessToken
      return await this.axios({
        url: 'getStudentInformation/' + id,
        method: 'get',
        headers: {
          Authorization: 'Bearer ' + this.header,
        },
      }).then(res => {
        this.studentInfo = res.data
      }).catch(error => {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem('logged_user')
          this.$router.push({ name: 'login-page' })
        }
      })
    },
    check () {
      this.isDisabled = this.checkbox === false
    },
    async fetchSelectedFaculty () {
      const url = window.location.pathname
      const id = url.substring(url.lastIndexOf('/') + 1)
      if (localStorage.getItem('logged_user')) {
        this.logger = JSON.parse(localStorage.getItem('logged_user'))
      }
      this.header = this.logger.accessToken
      try {
        const res = await this.axios({
          url: 'get_chosen_faculty/' + id,
          method: 'get',
          headers: {
            Authorization: 'Bearer ' + this.header,
          },
        })
        if (res.status === 200) {
          this.faculty = res.data
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login-page' })
        } else if (error.response && error.response.status === 400) {
          this.faculty = []
        } else {
          console.log(error)
        }
      }
    },
    async fetchSelectedClass () {
      if (localStorage.getItem('logged_user')) {
        this.logger = JSON.parse(localStorage.getItem('logged_user'))
      }
      this.header = this.logger.accessToken
      try {
        const res = await this.axios({
          url: 'get_selected_class/' + this.selected + '/'+ this.requestedLevel,
          method: 'get',
          headers: {
            Authorization: 'Bearer ' + this.header,
          },
        })
        if (res.status === 200) {
          this.classes = res.data
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login-page' })
        } else if (error.response && error.response.status === 400) {
          this.classes = []
        } else {
          console.log(error)
        }
      }
    },
    async fetchParentsInfo () {
      const url = window.location.pathname
      const id = url.substring(url.lastIndexOf('/') + 1)
      if (localStorage.getItem('logged_user')) {
        this.logger = JSON.parse(localStorage.getItem('logged_user'))
      }
      this.header = this.logger.accessToken
      try {
        const res = await this.axios({
          url: 'get_parent_info/' + id,
          method: 'get',
          headers: {
            Authorization: 'Bearer ' + this.header,
          },
        })
        if (res.status === 200) {
          this.parentsInfo = res.data
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem('logged_user')
          this.$router.push({ name: 'login-page' })
        } else if (error.response && error.response.status === 400) {
          this.parentInfo = []
          this.isVisible = 'moveon'
        } else {
          console.log(error)
        }
      }
    },
    async fetchStudyInfo () {
      const url = window.location.pathname
      const id = url.substring(url.lastIndexOf('/') + 1)
      if (localStorage.getItem('logged_user')) {
        this.logger = JSON.parse(localStorage.getItem('logged_user'))
      }
      this.header = this.logger.accessToken
      try {
        const res = await this.axios({
          url: 'get_study_info/' + id,
          method: 'get',
          headers: {
            Authorization: 'Bearer ' + this.header,
          },
        })
        if (res.status === 200) {
          this.btnNext = false
          this.studyInfo = res.data
          this.studyVisible = 'studyVisible'
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem('logged_user')
          this.$router.push({ name: 'login-page' })
        } else if (error.response && error.response.status === 400) {
          this.btnNext = true
          this.studydata = []
          this.studyVisible = 'studyNotVisible'
        } else {
          console.log(error)
        }
      }
    },
    async fetchStudentChoices () {
      const url = window.location.pathname
      const id = url.substring(url.lastIndexOf('/') + 1)
      if (localStorage.getItem('logged_user')) {
        this.logger = JSON.parse(localStorage.getItem('logged_user'))
      }
      this.header = this.logger.accessToken
      try {
        const res = await this.axios({
          url: 'get_choices_info/' + id,
          method: 'get',
          headers: {
            Authorization: 'Bearer ' + this.header,
          },
        })
        if (res.status === 200) {
          this.studentChoices = res.data.data
          this.studentClass = res.data.class
          this.requestedLevel=res.data.level
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem('logged_user')
          this.$router.push({ name: 'login-page' })
        } else if (error.response && error.response.status === 400) {
          this.btnNext = true
          this.studydata = []
          this.studyVisible = 'studyNotVisible'
        } else {
          console.log(error)
        }
      }
    },
    async fetchStudentsImages () {
      const url = window.location.pathname
      const id = url.substring(url.lastIndexOf('/') + 1)
      if (localStorage.getItem('logged_user')) {
        this.logger = JSON.parse(localStorage.getItem('logged_user'))
      }
      this.header = this.logger.accessToken
      try {
        const res = await this.axios({
          url: 'get_student_documents/' + id,
          method: 'get',
          headers: {
            Authorization: 'Bearer ' + this.header,
          },
        })
        if (res.status === 200) {
          this.documents = res.data
          this.imageShow = 'appear'
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem('logged_user')
          this.$router.push({ name: 'login-page' })
        } else if (error.response && error.response.status === 400) {
          this.images = []
          this.imageShow = 'moveon'
        } else {
          console.log(error)
        }
      }
    },
    async fetchSClassInfo () {
      if (localStorage.getItem('logged_user')) {
        this.logger = JSON.parse(localStorage.getItem('logged_user'))
      }
      this.header = this.logger.accessToken
      try {
        const res = await this.axios({
          url: 'get_class_info/' + this.classe,
          method: 'get',
          headers: {
            Authorization: 'Bearer ' + this.header,
          },
        })
        if (res.status === 200) {
          this.appear = true
          this.capacity = res.data.seats
          this.studentsInClass = res.data.students
          this.available = res.data.rest
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem('logged_user')
          this.$router.push({ name: 'login-page' })
        } else if (error.response && error.response.status === 400) {
          console.log(error)
        } else {
          console.log(error)
        }
      }
    },
    async approve () {
      this.loading = true
      this.isDisabled = true
      this.successmsg = 'You approved' + this.names.names
      const url = window.location.pathname
      const id = url.substring(url.lastIndexOf('/') + 1)
      if (localStorage.getItem('logged_user')) { this.logger = JSON.parse(localStorage.getItem('logged_user')) }
      this.header = this.logger.accessToken
      try {
        const res = await this.axios({
          url: 'approve_student/' + id,
          method: 'post',
          data: {
            faculty: this.selected,
            classe: this.classe,
          },
          headers: {
            Authorization: 'Bearer ' + this.header,
          },
        })
        if (res.status === 200) {
          this.loading = false
          this.isSuccess = true
          this.approved = true
          this.selected = ''
          this.classe = ''
          this.checkbox = false
          this.fetchStudentName()
          this.fetchStudentChoices()
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login-page' })
        } else {
          console.log(error)
        }
      }
    },
    async reject () {
      this.successmsg = 'You rejected' + this.names.names
      const url = window.location.pathname
      const id = url.substring(url.lastIndexOf('/') + 1)
      if (localStorage.getItem('logged_user')) { this.logger = JSON.parse(localStorage.getItem('logged_user')) }
      this.header = this.logger.accessToken
      if (this.msg === '') {
        alert('specify the reason ')
      } else {
        const data = {
          message: this.msg,
          id: this.id,
        }
        try {
          const res = await this.axios({
            url: 'reject_student/' + id,
            method: 'post',
            data: data,
            headers: {
              Authorization: 'Bearer ' + this.header,
            },
          })
          if (res.status === 200) {
            this.disableCheckbox = true
            this.isDisabled = true
            this.rejectModal = false
            this.isSuccess = true
          } else {
            alert('Data not saved')
          }
        } catch (e) {
          if (e.response && e.response.status === 401) {
            this.$router.push({ name: 'login-page' })
          } else if (e.response && e.response.status === 400) {
            alert(e.response.data.messages)
          } else {
            console.log(e)
          }
        }
      }
    },
    async fetchStudentName () {
      const url = window.location.pathname
      const id = url.substring(url.lastIndexOf('/') + 1)
      if (localStorage.getItem('logged_user')) { this.logger = JSON.parse(localStorage.getItem('logged_user')) }
      this.header = this.logger.accessToken
      try {
        const res = await this.axios({
          url: 'get_student_name/' + id,
          method: 'get',
          headers: {
            Authorization: 'Bearer ' + this.header,
          },
        })
        if (res.status === 200) {
          this.names = res.data
          const jsonData = JSON.parse(this.names.extra)
          // const date = new Date(jsonData.approved_at * 1000)
          const date = new Date()
          date.setTime(jsonData.approved_at * 1000)
          this.approvedAt = date.toDateString()
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login-page' })
        } else {
          console.log(error)
        }
      }
    },
  },
}
</script>

<style scoped>
.info {
  padding: 5px;
  background-color: #f2f2f2 !important;
  width: 300px;
  border-bottom: 1px solid gray !important;
  border-radius: 3px 3px 0 0;
  box-shadow: 1px 4px 8px 4px rgba(85,114,135,0.49);
  -webkit-box-shadow: 1px 1px 8px 1px rgba(85,114,135,0.1);
  -moz-box-shadow: 1px 4px 8px 4px rgba(85,114,135,0.1);
}
  .v-tab {
    justify-content: left !important;
    text-transform: capitalize;
  }
.v-sheet.v-card:not(.v-sheet--outlined) {
  -webkit-box-shadow: unset !important;
  box-shadow:unset !important;
}
.v-sheet:not(.v-sheet--outlined) {
  -webkit-box-shadow: unset !important;
  box-shadow:unset !important;
}
element {

}

.v-sheet:not(.v-sheet--outlined) {

  -webkit-box-shadow: unset !important;
  box-shadow:unset !important;

}
.v-card--flat {
  -webkit-box-shadow: unset !important;
  box-shadow:unset !important;

}
</style>
